export const appointmentsData = [
  {
    name: "Ana Luiza",
    date: "21 de Abril, 2024 às 18:00",
    value: "120,00",
    image: "",
    age: 21,
    gender: "Mulher",
    location: "Consultório José Montenegro",
    logo: "",
    aditionalInfo:
      "Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum ",
  },
  {
    name: "Luiz Antônio",
    date: "21 de Abril, 2024 às 19:00",
    value: "120,00",
    image: "",
    age: 23,
    gender: "Homem",
    location: "Consultório José Montenegro",
    logo: "",
    aditionalInfo:
      "Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum ",
  },
  {
    name: "João Azevedo",
    date: "21 de Abril, 2024 às 20:00",
    value: "120,00",
    image: "",
    age: 35,
    gender: "Homem",
    location: "Consultório José Montenegro",
    logo: "",
    aditionalInfo:
      "Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum ",
  },
];

export const info = [
  {
    patient: "João Gui",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Júlia Santos",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Jacinto",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Gabriel Silva",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Tierri jamilton",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Tierri jamilton",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
  {
    patient: "Tierri jamilton",
    date: "16/02/2024",
    hour: "16:00",
    status: "Finalizado",
    image: "",
  },
];
