import { Link } from "@inertiajs/react";
import { Button, Text } from "@switchdreams/ui";
import React from "react";

import { imagePath } from "../../../utils";

const InfoSection = () => {
  return (
    <div className="flex flex-col gap-8 pb-10" id="about">
      <div className="relative flex w-full items-center justify-center gap-10 py-14 max-lg:flex-col">
        <div className="absolute left-0 top-0 w-1/5 rounded-r-2xl bg-secondary-75 max-lg:w-1/2 max-md:h-[17%] md:h-[42%] lg:h-full" />
        <img
          src={imagePath("doctorCheckup.webp")}
          className=" z-10 w-[30%] max-lg:w-3/5"
          alt="médica fazendo um exame"
        />
        <div className="z-10 flex w-1/2 flex-col justify-center gap-5 max-lg:w-full max-lg:items-center max-lg:pb-5 max-lg:text-center xl:px-44">
          <Text className="font-poppins text-lg font-semibold text-secondary-900">SOBRE</Text>
          <Text className="font-quickSand text-5xl font-semibold text-coolGray-950 max-md:text-4xl">
            Mas, o que nós fazemos?
          </Text>
          <div className="flex flex-col gap-5">
            <Text className="text-md font-regular font-poppins text-coolGray-600">
              No <b>viaconsultas</b>, estamos comprometidos em revolucionar o acesso à saúde,
              conectando pacientes e profissionais de forma inovadora e eficiente. Somos uma
              plataforma dedicada a proporcionar uma experiência de atendimento particular de alta
              qualidade em diversas especialidades, eliminando as barreiras tradicionais e
              oferecendo soluções acessíveis e convenientes para todos.
            </Text>
            <Text className="text-md font-regular font-poppins text-coolGray-600">
              O que Fazemos:
              <br /> Nossa missão é simples: facilitar o encontro entre pacientes em busca de
              atendimento particular e médicos e profissionais de saúde excepcionais. Através de
              nossa plataforma intuitiva e abrangente, pacientes podem encontrar os melhores
              especialistas, agendar consultas com facilidade e desfrutar de atendimento
              personalizado e de excelência.
            </Text>
            <Text className="text-md font-regular font-poppins text-coolGray-600">
              Para os profissionais de saúde, oferecemos uma oportunidade única de expandir sua base
              de pacientes particulares, aumentar sua visibilidade e independência em relação aos
              planos de saúde, e proporcionar uma experiência de atendimento diferenciado, onde a
              qualidade e a acessibilidade caminham juntas.
            </Text>
            <Text className="text-md font-regular font-poppins text-coolGray-600">
              Em resumo, no viaconsultas, estamos redefinindo os padrões de cuidados de saúde,
              unindo tecnologia e humanidade para criar um futuro onde todos tenham acesso a um
              atendimento de qualidade, quando e onde precisarem. Junte-se a nós nessa jornada rumo
              a uma saúde mais acessível, eficiente e humana.
            </Text>
          </div>
          <Link href="/sign_up">
            <Button
              label="Marque uma consulta"
              className="flex max-w-60 rounded-2xl bg-primary-500 px-6 py-2 font-poppins text-sm font-medium text-white duration-500 hover:bg-primary-100"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
