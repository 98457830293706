import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@inertiajs/react";
import { Button, Text } from "@switchdreams/ui";
import React from "react";

import { imagePath } from "../../../utils";

const Options = [
  {
    label: "Clínica geral",
    img: imagePath("Stethoscope.svg"),
  },
  {
    label: "Pediatria",
    img: imagePath("doctorWithKid.svg"),
  },
  {
    label: "Ortopedia",
    img: imagePath("bones.svg"),
  },
  {
    label: "Cardiologia",
    img: imagePath("hearth.svg"),
  },
  {
    label: "Ginecologia",
    img: imagePath("femaleGender.svg"),
  },
  {
    label: "Psiquiatria",
    img: imagePath("Brain.svg"),
  },
  {
    label: "Dermatologia",
    img: imagePath("skincare.svg"),
  },
  {
    label: "Odontologia",
    img: imagePath("Tooth.svg"),
  },
  {
    label: "Fisioterapia",
    img: imagePath("terapy.svg"),
  },
  {
    label: "Psicologia",
    img: imagePath("think.svg"),
  },
  {
    label: "Nutrição",
    img: imagePath("apple.svg"),
  },
];

const AppealSection = () => {
  return (
    <div className="flex w-full justify-center bg-primary-500 py-36 max-lg:px-6">
      <div className="flex max-w-screen-big flex-col items-center gap-16 max-xl:px-6">
        <div className="flex w-full flex-col items-start max-lg:items-center max-lg:justify-center">
          <Text className="mb-8 font-poppins text-lg font-semibold text-secondary-900">
            ESPECIALIDADES
          </Text>
          <div className="flex w-full items-start justify-between max-lg:justify-center">
            <Text className="font-quickSand text-5xl font-semibold text-white max-xl:text-4xl max-lg:text-center">
              As especialidades mais procuradas
            </Text>
            <Link href="/sign_up">
              <Button
                label="Busque um especialista"
                className="flex w-fit rounded-2xl border border-primary-400 bg-primary-500 px-6 py-2 font-poppins text-sm font-medium text-white duration-500 hover:bg-primary-100 max-lg:hidden"
                iconSide="right"
                icon={ArrowLongRightIcon}
              />
            </Link>
          </div>
          <Text className="text-md font-regular mt-8 font-poppins text-coolGray-300 max-lg:text-center">
            Oferecemos uma ampla variedade de especialidades médicas para atender às suas
            necessidades de saúde.
          </Text>
        </div>
        <div className="flex flex-none flex-wrap items-center gap-4 max-lg:justify-center">
          {Options.map((option, idx) => {
            return (
              <div className="flex gap-1 rounded-2xl bg-primary-400 px-6 py-4" key={idx}>
                <img src={option.img} />
                <Text className="font-quickSand text-xl font-semibold text-white">
                  {option.label}
                </Text>
              </div>
            );
          })}
        </div>
        <Link href="/sign_up">
          <Button
            label="Cadastre-se agora"
            className="flex w-full rounded-2xl border border-primary-400 bg-primary-500 px-10 py-2 font-poppins text-sm font-medium text-white duration-500 hover:bg-primary-600 lg:hidden"
            iconSide="right"
            icon={ArrowLongRightIcon}
          />
        </Link>
      </div>
    </div>
  );
};

export default AppealSection;
