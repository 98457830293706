export const infos = [
  {
    plan: "trimestral",
    value: "R$ 150,00",
    profissionalNumber: 1,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "Semestral",
    value: "R$ 150,00",
    profissionalNumber: 3,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "Anual",
    value: "R$ 150,00",
    profissionalNumber: 10,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "trimestral",
    value: "R$ 150,00",
    profissionalNumber: 1,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "Semestral",
    value: "R$ 150,00",
    profissionalNumber: 3,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "Anual",
    value: "R$ 150,00",
    profissionalNumber: 10,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
  {
    plan: "Semestral",
    value: "R$ 150,00",
    profissionalNumber: 3,
    paymentDate: "10/05/2021",
    planLimit: "10/05/2021",
    status: "Ativo",
  },
];
