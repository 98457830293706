export const DoctorInfo = [
  {
    name: "Dr. José Montenegro",
    verified: true,
    img: "",
    specility: "Clínico Geral e Cardiologista",
    timeOnSite: "3 meses",
    patients: 235,
    description:
      "Formado em Medicina e especializado em Cardiologia pela Unifesp. Com mais de 14 anos de experiência clínica, estou comprometido em oferecer diagnósticos precisos e tratamentos personalizados a todos.",
    starts: 5,
    evaluations: 36,
    clinicName: "Consultório José Montenegro",
    address: "Praça Santo Agostinho 70, Brasília",
    logo: "",
    dates: [
      {
        day: "Segunda",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Terça",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Quarta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Quinta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sexta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sábado",
        times: ["8:00", "9:00", "10:00"],
      },
    ],
  },
  {
    name: "Dra. Maria Silva",
    verified: true,
    img: "",
    specility: "Pediatra",
    timeOnSite: "1 ano",
    patients: 320,
    description:
      "Especialista em Pediatria pela USP, com 10 anos de experiência. Apaixonada por cuidar da saúde das crianças, proporcionando um atendimento humanizado e de qualidade.",
    starts: 5,
    evaluations: 45,
    clinicName: "Clínica Pediátrica Maria Silva",
    address: "Rua das Flores 123, São Paulo",
    logo: "",
    dates: [
      {
        day: "Segunda",
        times: ["9:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
      },
      {
        day: "Terça",
        times: ["9:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
      },
      {
        day: "Quarta",
        times: ["9:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
      },
      {
        day: "Quinta",
        times: ["9:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
      },
      {
        day: "Sexta",
        times: ["9:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
      },
      {
        day: "Sábado",
        times: ["9:00", "10:00"],
      },
    ],
  },
  {
    name: "Dr. Carlos Andrade",
    verified: true,
    img: "",
    specility: "Dermatologista",
    timeOnSite: "6 meses",
    patients: 180,
    description:
      "Formado pela UFRJ com especialização em Dermatologia. Experiência de 8 anos no tratamento de doenças de pele, cabelo e unhas, com foco em procedimentos estéticos.",
    starts: 4,
    evaluations: 27,
    clinicName: "Clínica Dermatológica Carlos Andrade",
    address: "Av. Atlântica 456, Rio de Janeiro",
    logo: "",
    dates: [
      {
        day: "Segunda",
        times: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      },
      {
        day: "Terça",
        times: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      },
      {
        day: "Quarta",
        times: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      },
      {
        day: "Quinta",
        times: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      },
      {
        day: "Sexta",
        times: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      },
      {
        day: "Sábado",
        times: ["10:00", "11:00", "12:00"],
      },
    ],
  },
  {
    name: "Dra. Fernanda Costa",
    verified: true,
    img: "",
    specility: "Ginecologista e Obstetra",
    timeOnSite: "2 anos",
    patients: 410,
    description:
      "Especialista em Ginecologia e Obstetrícia pela UNICAMP. Com mais de 12 anos de experiência, foco em saúde da mulher, acompanhamento pré-natal e partos humanizados.",
    starts: 5,
    evaluations: 52,
    clinicName: "Centro de Saúde da Mulher Fernanda Costa",
    address: "Rua dos Pinheiros 789, Campinas",
    logo: "",
    dates: [
      {
        day: "Segunda",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Terça",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Quarta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Quinta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sexta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sábado",
        times: ["8:00", "9:00", "10:00"],
      },
    ],
  },
  {
    name: "Dr. Paulo Moreira",
    verified: true,
    img: "",
    specility: "Ortopedista",
    timeOnSite: "9 meses",
    patients: 290,
    description:
      "Graduado pela USP com especialização em Ortopedia e Traumatologia. Experiência de 11 anos em tratamentos de fraturas, lesões esportivas e cirurgias ortopédicas.",
    starts: 4,
    evaluations: 40,
    clinicName: "Ortopedia Paulo Moreira",
    address: "Rua das Palmeiras 250, Belo Horizonte",
    logo: "",
    dates: [
      {
        day: "Segunda",
        times: [
          "7:00",
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
        ],
      },
      {
        day: "Terça",
        times: [
          "7:00",
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
        ],
      },
      {
        day: "Quarta",
        times: [
          "7:00",
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
        ],
      },
      {
        day: "Quinta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sexta",
        times: [
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
        ],
      },
      {
        day: "Sábado",
        times: ["8:00", "9:00", "10:00"],
      },
    ],
  },
];

export const Options = [
  "Alergia e Imunologia",
  "Dermatologista",
  "Neurocirurgia",
  "Infectiologia",
  "Anestesiologia",
  "Coloproctologia",
  "Neurologia",
  "Mastologia",
  "Angiologia",
  "Cirurgia Torácica",
  "Nutrologia",
  "Medicina Física e Reabilitação",
  "Cancerologia / Oncologia",
  "Cirurgia Vascular",
  "Oftalmologia",
  "Nefrologia",
  "Cardiologia",
  "Endocrinologia",
  "Ortopedia e Traumatologia",
  "Neurocirurgia",
  "Cirurgia Cardiovascular",
  "Gastroenterologia",
  "Otorrinolaringologia",
  "Neurologia",
  "Cirurgia de Mão",
  "Genética Médica",
  "Pediatria",
  "Nutrologia",
  "Cirurgia de Cabeça e Pescoço",
  "Geriatria",
  "Pneumologia",
  "Oftalmologia",
  "Cirurgia de Aparelho Digestivo",
  "Ginecologia e Hemoterapia",
  "Psiquiatria",
  "Ortopedia e Traumatologia",
  "Cirurgia Geral",
  "Infectiologia",
  "Reumatologia",
  "Otorrinolaringologia",
  "Cirurgia Pediátrica",
  "Mastologia",
  "Urologia",
  "Cirurgia Plástica",
  "Medicina Física e Reabilitação",
  "Clínica de Exames Radiológicos",
  "Pneumologia",
  "Clínica Médica",
  "Nefrologia",
  "Laboratórios de Análises Clínicas e Patológicas",
];
